<template>
	<div class="mstp-map">
		<sidebar />
		<div class="right">
			<header-top />
			<div class="page-title">
				<span>实时能耗</span>
				<div class="title-right">
					<ul>
						<li>首页</li>
						<li>/</li>
						<li>能耗统计</li>
						<li>/</li>
						<li>能耗报表</li>
					</ul>
				</div>
			</div>
			<div class="firstRow">
				<div class="title">
					能耗报表
				</div>
				<div class="title_btn">
					<span><i class="el-icon-s-data"></i> 图形视图</span>
					<span><i class="el-icon-s-grid"></i> 表格视图</span>
				</div>
			</div>
			<div class="bottomPart">
				<div class="my-tab">
					<ul>
						<li class="active">总耗能</li>
						<li>总用电</li>
						<li>空调系统用电</li>
						<li>照明用电</li>
						<li>碳排放量</li>
					</ul>
				</div>
				<div class="tab-content">
					<div class="left-region">
						<div class="leftInfo">
							<h2>总能耗报表</h2>
							<h4>含总耗能、总用电、总用水、总用气等数据。</h4>
						</div>
					</div>
					<div class="right-region">
						<div class="top">
							<span>选择分类</span>
							<select class="select">
								<option>A1号楼</option>
								<option>A2号楼</option>
							</select>
							<span>选择类型</span>
							<select class="select">
								<option>日报</option>
								<option>其他</option>
							</select>
							<span>选择日期</span>
							<el-date-picker
								type="daterange"
								range-separator="至"
								start-placeholder="开始日期"
								end-placeholder="结束日期"
								size="mini"
								v-model="date"
							>
							</el-date-picker>
							<div class="btn">
								<span class="search"><i class="el-icon-search"></i></span>
								<span class="download"><i class="el-icon-finished"></i></span>
							</div>
						</div>
						<div class="graph">
							<div class="points">
								<div class="graphTitle">
									总耗能
								</div>
								<div class="nullBox"></div>
							</div>
							<div class="points">
								<div class="graphTitle">
									总耗能分项
								</div>
								<div class="nullBox"></div>
							</div>
						</div>
						<div class="graph">
							<div class="points">
								<div class="graphTitle">
									总耗能
								</div>
								<div class="title">
									<span>时间</span>
									<span>当前值</span>
								</div>
							</div>
							<div class="points">
								<div class="graphTitle">
									总耗能分项
								</div>
								<div class="title">
									<span>说明项</span>
									<span>当前值</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import sidebar from './components/sidebar.vue'
import headerTop from './components/header.vue'
export default {
	components: {
		sidebar,
		headerTop,
	},
	data() {
		return {
			date: '',
		}
	},
}
</script>

<style lang="scss" scoped>
.mstp-map {
	*:focus,
	*:active {
		outline: none !important;
	}
	li {
		list-style: none;
	}
	background: url(~@/assets/images/energyManage/background.jpg) 700px 700px;
	display: flex;
	.right {
		flex: 8;
		padding: 0 20px;
		font-size: 14px;
		.page-title {
			font-size: 15px;
			border-bottom: 1px solid rgba(255, 255, 255, 0.15);
			margin: 0;
			display: flex;
			color: #fff;
			justify-content: space-between;
			align-items: center;
			padding: 10px 0;
			.title-right {
				ul {
					display: flex;
					margin: 0;
					li {
						font-size: 13px;
						margin: 0 5px;
						cursor: pointer;
					}
					li:last-child {
						color: #ffd600;
					}
					li:nth-child(1):hover,
					li:nth-child(3):hover,
					li:nth-child(5):hover {
						color: #ffd600;
					}
				}
			}
		}
		.firstRow {
			display: flex;
			justify-content: space-between;
			border-bottom: 2px solid #8a8e9b;
			.title {
				width: 75%;
				display: inline-block;
				height: 38px;
				border-left: 5px solid #67cef7;
				font-size: 32px;
				color: white;
				padding-left: 10px;
			}
			.title_btn {
				span {
					color: #fff;
					height: 36px;
					width: 106px;
					display: inline-block;
					background: #e64e67;
					border-radius: 5px;
					text-align: center;
					line-height: 36px;
					margin-right: 5px;
					cursor: pointer;
				}
				span:last-child {
					background: #515a7b;
					margin-right: 0;
				}
			}
		}
		.bottomPart {
			.my-tab {
				ul {
					display: flex;
					padding: 0;
					margin: 0;
					li {
						flex: 1;
						background-color: #323a55;
						color: #fff;
						height: 43px;
						text-align: center;
						line-height: 43px;
						overflow: hidden;
					}
					.active {
						border-top: 3px solid #12a4a0 !important;
						line-height: 40px;
					}
				}
			}
			.tab-content {
				display: flex;
				.left-region {
					flex: 1;
					display: inline-block;
					background-color: #fab051;
					max-height: 265px;
					.leftInfo {
						margin: 40% 5% auto 10%;
						color: white;
						h2 {
							font-size: 30px;
						}
						h4 {
							font-size: 18px;
						}
					}
				}
				.right-region {
					flex: 2.5;
					display: inline-block;
					background-color: white;
					.top {
						padding: 5px 10px;
						span {
							background-color: #adb2ce;
							display: inline-block;
							height: 33px;
							line-height: 33px;
							color: #fff;
						}
						.select {
							border-radius: 4px;
							border: 1px solid #dcdfe6;
						}
						.btn {
							float: right;
							span {
								display: inline-block;
								width: 45px;
								height: 36px;
								color: #fff;
								font-size: 20px;
								text-align: center;
								line-height: 36px;
								cursor: pointer;
								i {
									font-weight: 700;
								}
							}
							.search {
								border-radius: 4px 0 0 4px;
								background-color: #505a7b;
							}
							.search:hover {
								color: #000;
							}
							.download {
								border-radius: 0 4px 4px 0;
								background-color: #0ea7a9;
							}
							.download:hover {
								color: #000;
							}
						}
					}
					.graph {
						display: flex;
						.points {
							flex: 1;
							padding: 8px 0;
							.graphTitle {
								text-align: center;
							}
							.nullBox {
								height: 400px;
							}
							.title {
								display: flex;
								margin-bottom: 20px;
								span {
									flex: 1;
									padding: 5px 8px 6px;
									background: rgba(0, 0, 0, 0.35);
									border-right: 1px solid #ddd;
								}
								span:last-child {
									border-right: 0;
								}
							}
						}
					}
				}
			}
		}
	}
}
</style>
